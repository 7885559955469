import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute  } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppSettings, Settings } from './app.settings';
import { LaRifamosContext } from './context/laRifamosContext';
import { PushService } from './services/push.service';
import { WindowService } from './services/window.service';
import { ChannelService } from './services/channel.service';
import { WalletService } from './services/wallet.service';
import { CampaignService } from './services/campaign.service';

//declare let fbq:Function;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})


export class AppComponent implements OnInit{
  title = 'LaRifamos';
  reloadHeaderData: string;
  needShowCookiesMessage: boolean = false;

  public settings: Settings;
  
  constructor(public appSettings: AppSettings, private router: Router, public translate: TranslateService,
    private pushService: PushService, private windowService: WindowService, private context: LaRifamosContext,
    @Inject(PLATFORM_ID) private platformId: Object, private route: ActivatedRoute, private channelService: ChannelService, 
    private walletService: WalletService, private campaignService: CampaignService){
    this.settings = this.appSettings.settings;
    translate.addLangs(['es','en']);
    translate.setDefaultLang('es'); 
    translate.use('es');
    router.events.subscribe((y: NavigationEnd) => {
      if(isPlatformBrowser(this.platformId) && y instanceof NavigationEnd){
        this.saveChannels();
        this.evaluateShowCookiesMessage();
      }
    })
  }

  scrollToTop() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll({top: 0, left: 0, behavior: 'smooth'});
    }  
  }

  ngOnInit(): void {
    this.pushService.subscribeForNotifications();
    
  }

  isInMarketplaceMode():boolean{
    return this.context.marketPlaceMode;
  }

  evaluateShowCookiesMessage() {
    
      const cookiesAcceptedValue = localStorage.getItem('cookiesAccepted');
      this.needShowCookiesMessage = !(cookiesAcceptedValue != undefined && cookiesAcceptedValue !== null && cookiesAcceptedValue == 'true');
      if (!this.needShowCookiesMessage) {
        this.fireAcceptCookiesEvent();
      }
    
  }

  fireAcceptCookiesEvent() {
    this.windowService.pushAcceptCookiesField();
    
  }

  acceptCookies() {
    localStorage.setItem('cookiesAccepted', 'true');
    this.fireAcceptCookiesEvent();
    this.deactiveCookiesView();
  }

  deactiveCookiesView() {
    this.needShowCookiesMessage = false;
  }

  onActivate(elementRef) {
    this.scrollToTop();
    if (elementRef && elementRef.successfullSignup) {
      elementRef.successfullSignup.subscribe(event => {
        this.reloadHeaderData = event;
      });
    }
  }

  saveChannels(){
    if (this.route.snapshot.queryParamMap.get('chn') !== null) {
      console.log('channel ' + this.route.snapshot.queryParamMap.get('chn'));
      this.channelService.setChannel(this.route.snapshot.queryParamMap.get('chn'));
    }

    if (this.route.snapshot.queryParamMap.get('friendcode') !== null) {
      console.log('fiendcode ' + this.route.snapshot.queryParamMap.get('friendcode'));
      this.walletService.setFriendCode(this.route.snapshot.queryParamMap.get('friendcode'));
    }

    if (this.route.snapshot.queryParamMap.get('purchasepin') !== null) {
      console.log('purchasepin ' + this.route.snapshot.queryParamMap.get('purchasepin'));
      this.campaignService.setPurchasePin(this.route.snapshot.queryParamMap.get('purchasepin'));
    }
  }
 

}
