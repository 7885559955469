import { ActiveWalletDetail } from "./active-wallet-detail";
import { CampaignEvaluationRequest } from "./campaing-evaluation-request";

export class PurchaseEvaluationRequest extends CampaignEvaluationRequest{
    type: string = 'purchase-request';
    raffleId: string;
    quantity: number;
    unitAmount: number;
    activeWalletDetails: Array<ActiveWalletDetail>;
    pin:string;
    firstPurchase:boolean;

}