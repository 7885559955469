import { Raffle } from '../entities/raffle';
import { Injectable } from '@angular/core';
import { User } from '../entities/user';
import { Ticket } from '../entities/ticket';
import { PurchaseResponse } from '../entities/purchase-response';
import { UserParticipationFilter } from '../context/user-participations-filter';
import { Payment } from '../entities/payment/payment';
import { ParticipationsStepsEnum } from '../components/participations/participations.component';
import { RaffleTermsAndConditions } from '../entities/raffle-terms-and-conditions';
import { CancelReasonEnum } from '../entities/cancel-reason-enum';
import { Quotation } from '../components/simulator/entity/quotation';
import { Currency } from '../entities/currency';
import { WalletUse } from '../entities/wallet/wallet-use';
import { PurchaseRequest } from '../entities/purchase-request';
import { SellerDTO } from '../entities/seller';
import { Owner } from '../entities/owner';
import { Discount } from '../entities/campaigns/discount';

import * as uuid from 'uuid';


@Injectable({
    providedIn: 'root'
})
export class LaRifamosContext {

    contextId: string;
    selectedRaffle: Raffle;
    currentUser: User;
    currentStep: ParticipationsStepsEnum;

    purchaseResponse: PurchaseResponse;

    selectedPaymentModeId: string;
    selectedPaymentModeType: string;
    userParticipationFilter = new UserParticipationFilter;

    raffleTermsAndConditions: RaffleTermsAndConditions;
    currencies: Array<Currency>;
    showCampaignsOption: boolean = false;


    currentPurchase: PurchaseRequest;
    lastPayment: Payment;

    seller: SellerDTO;

    marketPlaceMode: boolean = true;



    constructor() { }


    public cleanContext(): void {
        this.selectedRaffle = null;
        this.currentUser = null;
        this.purchaseResponse = null;
        this.userParticipationFilter = new UserParticipationFilter;
        this.selectedPaymentModeId = null;
        this.selectedPaymentModeType = null;
        this.clearQuotation();
        this.lastPayment = null;
        this.seller = null;
        this.resetCurrentPurchase();
    }

    public cleanPurchaseContext() {
        this.purchaseResponse = null;
        this.resetCurrentPurchase();
        this.selectedPaymentModeId = null;
        this.selectedPaymentModeType = null;

    }

    resetCurrentPurchase() {
        this.currentPurchase = new PurchaseRequest();
        this.contextId = uuid.v4();
    }

    public selectRaffle(currentRaffle: Raffle): void {
        this.selectedRaffle = currentRaffle;
        this.loadCurrentDrawImages();
    }
    public saveQuotation(quotation: Quotation) {
        localStorage.setItem('quotation', JSON.stringify(quotation));
    }
    public clearQuotation() {
        localStorage.setItem('quotation', null);
    }
    public loadQuotation(): Quotation {
        return JSON.parse(localStorage.getItem('quotation'));
    }
    loadCurrentDrawImages(): void {
        let currentDrawImages = new Array<String>();
        const nextDraw = this.selectedRaffle.nextDraw;
        for (let prizeIndex = 0; prizeIndex < nextDraw.prizes.length; prizeIndex++) {
            const prize = nextDraw.prizes[prizeIndex];
            for (let itemIndex = 0; itemIndex < prize.items.length; itemIndex++) {
                const item = prize.items[itemIndex];
                if (item.images != undefined && item.images != null) {
                    for (let imageIndex = 0; imageIndex < item.images.length; imageIndex++) {
                        const index = currentDrawImages.indexOf(currentDrawImages.find(imgSrc => imgSrc === item.images[imageIndex]));
                        if (index == -1) {
                            currentDrawImages.push(item.images[imageIndex]);
                        }
                    }
                }
            }
        }
        this.selectedRaffle.currentDrawImages = currentDrawImages;
    }

    public registerUserOnContext(user: User): void {
        this.currentUser = user;
    }
    public registerPurchaseResponseOnContext(purchaseResponse: PurchaseResponse): void {
        this.purchaseResponse = purchaseResponse;
    }
    public unregisterUserOnContext(): void {
        this.currentUser = null;
    }
    public unregisterPurchaseResponseOnContext(): void {
        this.purchaseResponse = null;
    }

    public selectTicket(ticket: Ticket): void {
        this.currentPurchase.addTicket(ticket);
    }

    public selectTickets(tickets: Ticket[]) {
        this.currentPurchase.addTickets(tickets);
    }

    public unselectTicket(ticket: Ticket): void {
        this.currentPurchase.removeTicket(ticket);
    }


    public unselectRaffle(): void {
        this.selectedRaffle = null;
        this.resetCurrentPurchase();
    }

    public cleanTicketSelection(): void {
        this.resetCurrentPurchase();
    }

    public cleanUserParticipationFilter(): void {
        this.userParticipationFilter = new UserParticipationFilter();
    }

    public ticketIsSelected(ticket: Ticket): Boolean {
        return this.currentPurchase.ticketIsSelected(ticket);
    }



    fillRaffleTermsAndConditions(): void {
        const body = this.getSelectedRaffleTermsAndConditions();
        if (body) {
            this.raffleTermsAndConditions = new RaffleTermsAndConditions();
            this.raffleTermsAndConditions.title = 'Términos y condiciones rifa ' + this.getSelectedRaffleTitle();
            this.raffleTermsAndConditions.body = body;
        }
    }

    cleanRaffleTermsAndConditions() {
        this.raffleTermsAndConditions = undefined;
    }

    currentRaffleState(): string {
        return this.selectedRaffle.state;
    }

    currentRaffleQuantityPurchaseBeforeCanceled(): number {
        return this.selectedRaffle.quantityPurchaseBeforeCanceled;
    }

    currentRaffleMaxTicketNumber(): number {
        return this.selectedRaffle.maxTicketNumber;
    }

    currentRaffleQuantityPurchase(): number {
        return this.selectedRaffle.quantityPurchase;
    }

    currentRaffleMinimumTicketNumbers(): number {
        return this.selectedRaffle.minimumTicketNumbers;
    }

    currentRaffleHasWinner(): boolean {
        let hasWinner = true;
        for (let index = 0; index < this.selectedRaffle.nextDraw.prizes.length; index++) {
            if (!this.selectedRaffle.nextDraw.prizes[index].hasWinner) {
                hasWinner = false;
                break;
            }
        }
        return hasWinner;
    }

    currentRaffleId(): string {
        return this.selectedRaffle.id;
    }

    currentRafflePricePerNumber(): number {
        return this.selectedRaffle.pricePerNumber;
    }

    currentRaffleCurrency(): string {
        return this.selectedRaffle.currency.symbol;
    }

    currentRaffleNextDrawDate(): String {
        return this.selectedRaffle.nextDraw.date;
    }

    currentRaffleValidationDate(): String {
        return this.selectedRaffle.validationDate;
    }

    currentRaffleDescription(): string {
        return this.selectedRaffle.description;
    }

    currentRaffleDrawMechanism(): string {
        return this.selectedRaffle.nextDraw.drawMechanism;
    }

    currentRafflePrizeDeliveryDate(): string {
        return this.selectedRaffle.nextDraw.prizes[0].deliveryDate;
    }

    currentRafflePrizeDeliveryLocation(): string {
        return this.selectedRaffle.nextDraw.prizes[0].deliveryLocation;
    }

    currentRaffleDrawDescriptionExtra(): string {
        return this.selectedRaffle.nextDraw.descriptionExtra;
    }

    currentRaffleBusinessConditionMessage(): string {
        for (let index = 0; index < this.selectedRaffle.businessConditions.length; index++) {
            const condition = this.selectedRaffle.businessConditions[index];
            if (condition.type === 'TICKET_BY_OWNER_CONDITION' || condition.type === 'TICKET_BY_PURCHASE_CONDITION') {
                if (condition.maxCondition === 1) {
                    return 'Puedes comprar a lo sumo ' + condition.maxCondition + ' ticket';
                } else {
                    return 'Puedes comprar a lo sumo ' + condition.maxCondition + ' tickets';
                }
            } else if (condition.type === 'PURCHASE_BY_RAFFLE_CONDITION') {
                if (condition.maxCondition === 1) {
                    return 'Puedes comprar a lo sumo ' + condition.maxCondition + ' vez';
                } else {
                    return 'Puedes comprar a lo sumo ' + condition.maxCondition + ' veces';
                }
            }
        }
        return '';
    }

    currentRaffleCancelReason(): string {
        const cancelationReason = this.selectedRaffle.cancelationReason;
        if (cancelationReason === CancelReasonEnum[CancelReasonEnum.MINIUM_TICKETS_NOT_RESERVED]) {
            return "No se alcanzó la cantidad mínima de tickets";
        } else if (cancelationReason === CancelReasonEnum[CancelReasonEnum.BENEFICIARY_RAFFLE_CANCEL]) {
            return "Beneficiario ha cancelado la rifa";
        } else if (cancelationReason === CancelReasonEnum[CancelReasonEnum.RAFFLE_CANCELLED]) {
            return "La rifa fue cancelada";
        }
    }


    currentRaffleHasBusinessCondition(): boolean {
        return this.selectedRaffle.businessConditions && this.selectedRaffle.businessConditions.length > 0;
    }

    currentDrawImages(): Array<String> {
        if (this.selectedRaffle.currentDrawImages === undefined || this.selectedRaffle.currentDrawImages == null)
            this.loadCurrentDrawImages();

        return this.selectedRaffle.currentDrawImages;
    }

    currentRaffleTitle(): string {
        return this.selectedRaffle.title;
    }

    private getSelectedRaffleTitle(): string {
        return this.selectedRaffle.title;
    }

    private getSelectedRaffleTermsAndConditions(): string {
        return (this.selectedRaffle != null) ? this.selectedRaffle.termsAndConditions : null;
    }

    public drawId(): string {
        return this.selectedRaffle.nextDraw.id;
    }

    termsAndConditionsTitle(): string {
        if (!this.getSelectedRaffleTermsAndConditions()) {
            return 'Términos y condiciones de LaRifamos';
        } else {
            this.fillRaffleTermsAndConditions();
            return this.raffleTermsAndConditions.title;
        }
    }

    findCurrencySymbolByCode(currencyCode: string): string {
        if (this.currencies === undefined)
            return currencyCode;
        for (let index = 0; index < this.currencies.length; index++) {
            if (this.currencies[index].code === currencyCode)
                return this.currencies[index].symbol;
        }
    }


    couponIsSelected(coupon: WalletUse): Boolean {
        return this.currentPurchase.couponIsSelected(coupon);
    }

    unselectCoupon(coupon: WalletUse) {
        this.currentPurchase.removeDiscount(coupon);
    }

    selectCoupon(coupon: WalletUse) {
        this.currentPurchase.addDiscount(coupon);
    }

    purchaseTotal(): number {
        return Math.round((this.currentPurchase.total + Number.EPSILON) * 100) /    100;
    }

    fillAdditionalPurchaseData(user: User, pays:number, referredCode?: string) {
        this.currentPurchase.owner = this.createOwner(user);
        this.currentPurchase.raffleId = this.selectedRaffle.id;
        this.currentPurchase.referredCode = referredCode;
        this.currentPurchase.seller = this.seller;
        if (this.currentPurchase.payments.length > 0) {
            this.currentPurchase.payments[0].installments = pays;
        }
        this.currentPurchase.update();
    }


    createOwner(user: User): Owner {
        let owner = new Owner();
        owner.document = user.document;
        owner.phone = user.phone;
        owner.firstName = user.firstName;
        owner.lastName = user.lastName;
        owner.username = user.username;
        owner.documentType = user.documentType;
        return owner;
    }

    selectedTicketsQuantity(): number {
        return this.currentPurchase.tickets.length;
    }

    addPayment(payment: Payment) {
        this.currentPurchase.removePayment(this.lastPayment);
        this.currentPurchase.addPayment(payment);
        this.lastPayment = payment;
    }

    hasTicketsSelected(): boolean {
        return this.currentPurchase.tickets.length > 0;
    }

    selectedTickets(): Array<Ticket> {
        if (!this.currentPurchase)
            this.resetCurrentPurchase();

        return this.currentPurchase.tickets;

    }

    selectedCoupons(): Array<WalletUse> {
        return this.currentPurchase.discounts;
    }

    subTotalAsStr(): string {
        if (this.selectedRaffle && this.currentPurchase)
            return this.selectedRaffle.currency.symbol + this.currentPurchase.subtotal;

        return '';
    }

    subTotal() {
      return this.currentPurchase && this.currentPurchase.subtotal ? this.currentPurchase.subtotal : 0;
    }

    totalAsStr(): string {
        if (this.selectedRaffle && this.currentPurchase)
            return this.selectedRaffle.currency.symbol + this.purchaseTotal();

        return '';
    }

    total() {
      return this.selectedRaffle && this.currentPurchase ? this.purchaseTotal() : 0;
    }

    discountTotalAsStr(): string {
        if (this.selectedRaffle && this.currentPurchase)
            return this.selectedRaffle.currency.symbol + this.discountTotal();

        return '';
    }

    discountTotal(): number {
        return Math.round((this.currentPurchase.discountTotal + Number.EPSILON) * 100) /    100;
    }

    removeAllDiscounts() {
        this.currentPurchase.removeAllDiscount();
    }

    raffleMaxAvailableTicketsToPurchase(): number {
        return this.selectedRaffle.maxTicketNumber - this.selectedRaffle.quantityPurchase;
    }

    cleanAllRandomTicketSelected() {
        this.currentPurchase.cleanAllRandomTicketSelected();
    }


    fillSeller(sellerId: any, sellerDocument: any) {
    this.seller = new SellerDTO(sellerId, sellerDocument);
    }


    setDirectDiscounts(discounts:Discount[]) {
        this.currentPurchase.setDirectDiscounts(discounts);
    }
}
